const awsmobile = {
  aws_project_region: 'us-east-1',
  aws_cognito_identity_pool_id:
    'us-east-1:5867057f-c820-46c5-8def-1d42494b907b',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_RjHT2j4pO',
  aws_user_pools_web_client_id: '79ric96fgnq11nmfh8mer4gjma',
  oauth: {},
};

export default awsmobile;

